@import "react-slideshow-image/dist/styles.css";
/* @import url('https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto:300&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Inter+Tight:ital,wght@0,100..900;1,100..900&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');


.terminos {
  margin-left: 250px;
  margin-right: 250px;
  margin-top: 50px;
  margin-block-end: 50px;
}

.espacio-publicitario{
  height: 80px;
  text-align: center;
  font-size: 3em;
  padding: 50px;
}


.quienes-somos1 {
  margin-left: 200px;
  margin-right: 200px;
}

.prueba2 {

  text-align: center;
  background: linear-gradient(141deg, #ccc 25%, #eee 40%, #ddd 55%);
  color: #555;
  font-family: 'Roboto';
  font-weight: 300;
  font-size: 35px;
  padding-top: 5vh;
  height: 10vh;
  overflow: hidden;
  backface-visibility: hidden;
  perspective: 1000;
  transform: translate3d(0, 0, 0);
}

.pagos3 {
  background: linear-gradient(141deg, #f4b22e 25%, #ecd24b 40%, #f2f4cd 55%);
  backface-visibility: hidden;
  perspective: 1000;
  transform: translate3d(0, 0, 0);
}

.loading {
  filter: blur(10px);
  clip-path: inset(0);
}

.loaded {
  filter: blur(0px);
  transition: filter 0.5s linear;
}

.div1:first-of-type {

  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  animation-duration: 7s;
  animation-name: showup;
}

.div2:last-of-type {
  width: 0px;
  animation: reveal 7s infinite;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
  margin-left: 10px;
}



@keyframes showup {
  0% {
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  80% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes slidein {
  0% {
    margin-left: -800px;
  }

  20% {
    margin-left: -800px;
  }

  35% {
    margin-left: 0px;
  }

  100% {
    margin-left: 0px;
  }
}

@keyframes reveal {
  0% {
    opacity: 0;
    width: 0px;
  }

  20% {
    opacity: 1;
    width: 0px;
  }

  30% {
    width: 355px;
  }

  80% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    width: 355px;
  }
}

body {
  font-family: 'Inter', sans-serif;
  overflow-x: hidden;
}

.imagen-producto {
  width: 100%;
  text-align: center;
  padding-top: 20px;
  padding-right: 10px;
  height: 450px;
  display: flex;
  align-items: center;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

body {
  overflow: hidden;
}

.cuadros {
  width: 60%;
  margin: 0 auto;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.prueba1 {
  color: transparent;
  background-image: linear-gradient(to left, #FFD700, #7B68EE, #3498db, #2ecc71, #FFD700);
  background-clip: text;
  animation: color 3s linear infinite;
  background-size: 500%;
}

.header {
  /* background: linear-gradient(141deg, #b5c6df 25%, #b5cef3 40%, #81a3d6 55%); */
  /* background: linear-gradient(141deg, #fff 25%, #b5cef3 40%, #81a3d6 55%); */
  background-color: #dbe0ea;
  height: 110px;
  padding-left: 120px;
  padding-right: 120px;
  /* border: solid 1px black; */
  box-shadow: 0 5px 9px -7px;
  position: relative;
  z-index: 2;
}

.header-item-padre {
  display: table;
  height: 110px;
}

.header-item-hijo {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.text-menu {
  text-transform: none !important;
  font-weight: bold !important;
  font-size: 0.85em !important;
  /* width: 100%; */
  text-decoration: none;
  font-family: 'Roboto', sans-serif;
  color: #000;
}

.text-menu1 {
  text-transform: none !important;
  font-weight: bold !important;
  font-size: 0.85em !important;
  height: 37px;
  width: 90%;
}

.text-btn-init {
  text-transform: none !important;
  font-weight: 500 !important;
}

.container-div {
  overflow-y: auto;
  height: 100vh;
}

.container-div1 {
  overflow-y: auto;
  margin-left: 200px;
  margin-right: 200px;
}

.container-div2 {
  overflow-y: auto;
}

.container-div-login {
  overflow-y: auto;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-start {
  width: 570px;
  margin: 0 auto;
  border-radius: 15px !important;
  background-color: #ffffffeb;
  padding: 20px 50px 50px 50px;
  /* margin-top: 7%; */
}

.input-search {
  height: 30px;
  width: 90%;
  background-color: #efefef;
  border: #fff;
  border-radius: 20px;
  padding-left: 20px;
}

.padding-from {
  padding-top: 10px;
  text-align: center;
}

/* Tamaño del scroll */
::-webkit-scrollbar {
  width: 8px;
}

/* Estilos barra (thumb) de scroll */
::-webkit-scrollbar-thumb {
  background: #0174DF;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:active {
  background-color: #999999;
}

::-webkit-scrollbar-thumb:hover {
  background: #b3b3b3;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

/* Estilos track de scroll */
::-webkit-scrollbar-track {
  background: #e1e1e1;
  border-radius: 4px;
}

::-webkit-scrollbar-track:hover,
::-webkit-scrollbar-track:active {
  background: #d4d4d4;
}

.title-block {
  margin-top: 20px;
  font-size: 1.7em;
  font-weight: bold;
  color: #000;
}
.div-link-wellcome{
  width: 80%;
  margin: 0 auto;
  padding: 50px;
}

.link-wellcome{
  font-size: 2em;
  color: #2891b4;
}

.title-block1 {
  margin-top: 20px;
  font-size: 1.7em;
  font-weight: bold;
  color: #FFA500;
}

.subtext-principal {
  font-size: 1.2em;
  margin-top: 15px;
  margin-bottom: 25px;
  padding-left: 27px;
  padding-right: 27px;
}

.subtext-block1 {
  width: 600px;
  margin: 0 auto;
  margin-top: 5px;
  margin-bottom: 40px;
  font-size: 0.9em;
  color: #fff;
  text-align: center;
}

.circle-block1 {
  width: 65px;
  height: 65px;
  background-color: #000;
  border-radius: 50px;
}

.title-item-block1 {
  font-size: 0.8em;
  font-weight: bold;
  text-decoration: none;
}

.text-item-block1 {
  font-size: 0.8em;
  color: #9e9e9e;
  margin-top: 10px;
}

.tituloPlanes {
  text-align: center;
  margin-bottom: 10px;
  font-family: 'Fjalla One', sans-serif;
}

.precioPlanes {
  text-align: center;
}

.login-div {
  width: 700px;
  padding: 30px 0px;
  background-color: #F5F5F5;
  border-radius: 8px;
  display: flex;
  justify-content: center;
}

.form-login{
  width: 75%;
}

.title-login{
  font-weight: 600;
  font-size: 36px;
  line-height: 36px;
  text-align: center;
  color: #006CFA;
}

.text-login{
  font-weight: 500;
  font-size: 17px;
  line-height: 17px;
  text-align: center;
  text-decoration: none;
}

.text-color-blue{
  color: #006CFA;
}

.register-div {
  /* padding-top: 30px; */
  /* width: 100%;
  margin: 0 auto; */
  /* padding: 30px 0px; */
  padding-top: 30px;
  background-color: #F5F5F5;
  border-radius: 8px;
  display: flex;
  justify-content: center;
}

.container-imagen {
  position: relative;
  width: 250px;
  height: 250px;
  margin: 0 auto;
  margin-top: 10px;
}

.input-buscar {
  background-color: white;
  border-radius: 5px;
}

.contenedor-categoria {
  padding-left: 18px;
}

.contenedor-subCategoria {
  padding-left: 18px;
  border-left: dotted 1px #515151;
  margin-left: 19px;
}

.item-categoria {
  width: 100%;
}

.recordatorio {
  background-color: #FFA500;
  color: #000;
  padding: 10px;
  text-align: center;
  border-radius: 10px;
  width: 70%;
  margin: 0 auto;
}

.entregas {
  width: 100%;
  height: 560px;
  object-fit: cover;
  object-position: 100% 15%
}

.whatssap {
  background-color: #25D366;
  color: #000;
  padding: 10px;
  border-radius: 10px;
}

.compras {
  width: 100%;
  height: 560px
}

.denuncias {
  background-color: #259fae;
  color: #000;
  padding: 10px;
  text-align: center;
  border-radius: 10px;
  border: 5px;
  height: 50px;
  width: 400px;
}

.seguridad {
  width: 100%;
  height: 560px;
  object-fit: cover;
  object-position: 100% 25%
}

.quienes-somos {
  background-color: #8be4f0;
  color: #000;
  padding: 10px;
  text-align: center;
  border-radius: 10px;
}

.link-categoria {
  cursor: pointer;
}

.container-image1 {
  height: 480px;
  object-fit: cover;
  width: 100%;
  object-position: 80% 35%;
}

.container-image2 {
  height: 480px;
  object-fit: cover;
  width: 100%;
  object-position: 80% 35%;
}

.container-image3 {
  height: 480px;
  object-fit: cover;
  width: 100%;
  object-position: 80% 15%;
}

.factura {
  width: 49%;
  margin: 0 auto;
  padding-top: 30px;
}

.labelFacturacion {
  font-size: 1.2em;
  font-weight: bold;

}

.valueFacturacion {
  font-size: 1.2em;
}

.imagen {
  height: 250px;
  width: 250px;
  border-radius: 50%;
  object-fit: cover;
}

.imagen1 {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.div-element {
  margin-bottom: 16px;
  text-align: center;
}

.div-footer {
  width: 600px;
}

.text-footer {
  color: #d3cece;
  font-size: 0.8em;
  margin-bottom: 20px;
}

.categorias {
  /* background-Color: #71bf4a */
  
  background-Color:#c8e6eb ;


}

.text-precio {
  font-size: 45px;
  color: #ffcf22;
  text-shadow: 0px 1px #130c3b;
  font-weight: bold;

}

.text-precio1 {
  font-size: 45px;
  color: #1a03ac;
  text-shadow: 0px 1px #130c3b;
  font-weight: bold;
  text-align: center;

}

.pagos {
  /* background-Color: #07e4d6 */
}

.pagos1 {
  background-Color: #fff8e7;


}

.pagos2 {
  background-Color: #f2f1e8;
}



.botonPagos {
  background-Color: #1fea41;
}

.imagen-detalle {
  object-fit: contain;
  width: 100%;
  height: auto;
  max-height: 450px;
  max-width: 700px;
}

.imagen-item {
  object-fit: contain;
  width: 100%;
  height: auto;
  height: 70px;
  width: 70px;
}

.mensajePendiente {
  width: 100%;
  padding: 20;
  background-color: #2ecc71;
  border-radius: 10px;


}

.container-categorias {
  max-width: 1200px;
  margin: auto;
  display: flex;
  /*Establecemos la flex al contenedor*/
  flex-wrap: wrap;
  /*wrap para que los ítems sobrantes se desplacen a otra fila*/
  justify-content: center;


}

.item-categorias {
  padding: 10px;
  width: 25%;
  /*Establecemos el ancho en % de los ítems*/
  box-sizing: border-box;
  text-align: center;

}

.item-categorias img {
  width: 50%;
  height: auto;
}

.item-categorias .title {
  min-height: 60px;
  /*Para igualar columnas fijamos una altura para el título*/
}

.item-categorias p {
  min-height: 100px;
  /*Para igualar columnas fijamos una altura para el párrafo*/
}

.message-inactivo{
  width: 40%;
  margin: 0 auto;
  text-align: center;
  font-size: 3em;
  font-weight: bold; 
  margin-top: 15%; 
}

.border-detail{
  border-right: solid 1px #515151;
}

.container-planes-dashboard{
  max-width: 1200px;
  margin: auto;
  display: flex;
  /*Establecemos la flex al contenedor*/
  flex-wrap: wrap;
  /*wrap para que los ítems sobrantes se desplacen a otra fila*/
  justify-content: center;
}

.plan-dashboard{
  background: #fff895;
  padding: 20px;
  border-radius: 15px;
  width: 48%;
  /*Establecemos el ancho en % de los ítems*/
  box-sizing: border-box;
  margin: 10px;
  position: relative;
}

.container-planes-pagos{
  max-width: 1200px;
  margin: auto;
  display: flex;
  /*Establecemos la flex al contenedor*/
  flex-wrap: wrap;
  /*wrap para que los ítems sobrantes se desplacen a otra fila*/
  justify-content: center;
}

.plan-pago{
  background: #fff895;
  padding: 20px;
  border-radius: 15px;
  width: 30%;
  /*Establecemos el ancho en % de los ítems*/
  box-sizing: border-box;
  margin: 10px;
}

.link-text{
  text-decoration: none;
  color: blue;
  font-weight: bold;
}

.link-text:hover {
  text-decoration: none;
  color: #000550;
  font-weight: bold;
}

.title-producto{
  font-size: 1.2em;
  font-weight: bold;
  margin-top: 18px;
  margin-bottom: 5px;
}

.status-text{
  text-align: center;
  font-size: 0.8em;
  border-radius: 15px;
}

.status-text-blue{
  color: #000550;
  background-color: #00055040;
}

.status-text-orange{
  color: #d16703;
  background-color: #d1670340;
}

.status-text-green{
  color: #036613;
  background-color: #03661340;
}

.status-text-gray{
  color: #797979;
  background-color: #79797940;
}

/**************/
@media screen and (min-width: 1301px) and (max-width: 1334px) {
  .header {
    padding-left: 100px;
    padding-right: 100px;
  }
  .div-link-wellcome{
    width: 100%;
  }
  
  .link-wellcome{
    font-size: 2em;
  }

  .message-inactivo{
    width: 40%;
    margin-top: 15%; 
  }
}

@media screen and (min-width: 1195px) and (max-width: 1300px) {
  .header {
    padding-left: 50px;
    padding-right: 50px;
  }
  .div-link-wellcome{
    width: 80%;
  }
  
  .link-wellcome{
    font-size: 2em;
  }

  .message-inactivo{
    width: 40%;
    margin-top: 15%; 
  }
}

@media screen and (min-width: 959px) and (max-width: 1194px) {
  .header {
    padding-left: 5px;
    padding-right: 5px;
  }
  .div-link-wellcome{
    width: 80%;
  }
  
  .link-wellcome{
    font-size: 1.5em;
  }

  .message-inactivo{
    width: 40%;
    margin-top: 15%; 
  }
}

@media screen and (min-width: 481px) and (max-width: 959px) {
  .header {
    padding-left: 5px;
    padding-right: 5px;
  }

  .login-div {
    width: 600px;
    padding: 30px 0px;
  }
  
  .form-login{
    width: 85%;
  }

  .item-categorias {
    width: 50%;
  }

  .denuncias {
    width: 50%;
    border: 5px;
    height: 50px;
    width: 350px;
  }

  .entregas {
    width: 100%;
    height: 560px;
    object-fit: cover;
    object-position: 100% 15%
  }

  .compras {
    width: 100%;
    height: 330px
  }

  .prueba2 {

    text-align: center;
    background: linear-gradient(141deg, #ccc 25%, #eee 40%, #ddd 55%);
    color: #555;
    font-family: 'Roboto';
    font-weight: 300;
    font-size: 25px;
    padding-top: 5vh;
    height: 10vh;
    overflow: hidden;
    backface-visibility: hidden;
    perspective: 1000;
    transform: translate3d(0, 0, 0);
  }

  .recordatorio {
    background-color: #FFA500;
    color: #000;
    padding: 10px;
    text-align: center;
    border-radius: 10px;
    width: 90%;
  }
  .div-link-wellcome{
    width: 100%;
    padding: 5px;
  }
  
  .link-wellcome{
    font-size: 1.5em;
  }

  .message-inactivo{
    width: 60%;
    margin-top: 25%; 
  }

  .imagen-detalle {
    max-height: 450px;
    max-width: 700px;
  }

  .border-detail{
    border-right: none;
  }
}

@media screen and (max-width: 480px) {
  .header {
    padding-left: 5px;
    padding-right: 5px;
  }

  .imagen-producto {


    height: 300px;
  }


  .login-div {
    width: 95%;
    padding: 30px 0px;
  }
  
  .form-login{
    width: 90%;
  }

  .cuadros {
    width: 100%;
  }

  .card-start {
    width: 66%;
  }

  .subtext-block1 {
    width: 300px;
  }

  .div-footer {
    width: 300px;
  }

  .item-categorias {
    width: 50%;
  }

  .entregas {
    width: 100%;
    height: 300px;
    object-fit: cover;
    object-position: 100% 15%
  }


  .denuncias {
    border: 5px;
    height: 50px;
    width: 250px;
  }

  .compras {
    width: 100%;
    height: 250px
  }

  .prueba2 {
    text-align: center;
    background: linear-gradient(141deg, #ccc 25%, #eee 40%, #ddd 55%);
    color: #555;
    font-family: 'Roboto';
    font-weight: 300;
    font-size: 25px;
    padding-top: 5vh;
    height: 10vh;
    overflow: hidden;
    backface-visibility: hidden;
    perspective: 1000;
    transform: translate3d(0, 0, 0);
  }

  .recordatorio {
    background-color: #FFA500;
    color: #000;
    padding: 10px;
    border-radius: 10px;
    width: 90%;
  }

  .seguridad {
    width: 100%;
    height: 250px;
    object-fit: cover;
    object-position: 100% 25%
  }

  .container-image2 {
    height: 480px;
    object-fit: cover;
    width: 100%;
    object-position: 40% 25%;
  }

  .card-productos {
    height: 340px;
  }

  .imagen-detalle {
    max-height: 300px;
    max-width: 380px;
  }

  .card-productos1 {
    height: 150px;
  }

  .mostrar-categorias {
    margin-top: 20;
    margin-block-end: 150;
    background-color: '#FFFFFF';
  }

  .quienes-somos1 {
    margin-left: 5px;
    margin-right: 20px;
    height: 100%;
    width: 100%;
  }

  .terminos {
    margin-left: 20px;
    margin-right: 20px;
    margin-block-end: 50px;
  }
  
  .text-menu1 {
    text-transform: none !important;
    font-weight: bold !important;
    font-size: 0.85em !important;
    height: 37px;
    width: 80%;
  }

  .div-link-wellcome{
    width: 100%;
    padding: 5px;
  }
  
  .link-wellcome{
    font-size: 1em;
  }
  
  .message-inactivo{
    width: 100%;
    margin-top: 50%; 
  }

  .border-detail{
    border-right: none;
  }

}